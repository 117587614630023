import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function () {
      setTimeout(() => {
        $("#ava").on("click", function (e) {
          e.preventDefault();
          var codigo = "#" + $(this).data("ancla");
          $("html,body").animate({ scrollTop: $(codigo).offset().top }, 1);
        });
  
        $("#ava").trigger("click");
      }, 300);
    });
  }



  

}
